// Подгрузка стилей
[
    'http://system-eco.ru/wp-content/themes/systemeco/css/allstyle.css'
].forEach(function (src) {
    var link = document.createElement('link');
    link.href = src;
    link.type = 'text/css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
});

var vm = new Vue({
    el: '#app',
    data: {
      openedMenu: false,
      openedCallback: false
    },
    methods: {
      openBox: function(box, $event) {
        $('.finder-nav span').removeClass('active');
        $event.target.classList.add('active');
        $('.file-box').removeClass('opened');
        document.getElementById(box).classList.add('opened');
      }
    }
})

$(document).ready(function () {

  $('.totop').click(function () {
     $('body').animate( { scrollTop: 0 }, 1100 );
   });

  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $('.topline').addClass('active');
    }
    else {
      $('.topline').removeClass('active');
    }
  });

  $('.nav > span').click(function(){
    $(this).parent('.nav').toggleClass('opened')
  });
  $('.nav-nav > span').click(function(){
    $(this).parent('.nav-nav').toggleClass('opened')
  });

    // Маска телефона
    $(".phoneform").inputmask("+7(999)999-99-99", {
        "clearIncomplete": true
    });

    // Отправка
    $('.callbackform').each(function () {
        var $form = $(this);
        $form.validate({
            submitHandler: function () {
                $.ajax({
                    type: $form.attr('method'),
                    url: 'http://system-eco.ru/wp-content/themes/systemeco/secretmail.php',
                    data: $form.serialize()
                }).done(function () {
                    $form.slideUp(300);
                    $form.prev('.form-title').html('Заявка отправлена!');
                }).fail(function () {
                    $form.slideUp(300);
                    $form.prev('.form-title').html('Заявка отправлена!');
                });
            }
        });
    });
});
